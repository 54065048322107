import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import Amplify, { Auth } from 'aws-amplify'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

// Please replace the property values
const config = {
  aws: {
    region: environment.cognitoRegion,
    cognito: {
      userPoolId: environment.cognitoUserPoolId,
      userPoolWebClientId: environment.cognitoUserPoolWebClientId
    }
  },
  recaptcha: {
    secretKey: environment.recaptchaKey
  }
}
const aws_config = {
  Auth: {
    region: config.aws.region,
    userPoolId: config.aws.cognito.userPoolId,
    userPoolWebClientId: config.aws.cognito.userPoolWebClientId,
    cookieStorage: {
      domain: environment.domain
    }
  }
}
Amplify.configure(aws_config)
// Configure Amplify
Auth.configure(aws_config)
console.log(Auth)

if (environment.production) {
  enableProdMode()
  // TODO - prior to going to production need to find a way to change aws_config to 'www.deki.app'
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err))
