import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http'
import { Observable, from, of } from 'rxjs'
import { catchError, mergeMap, tap } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { Router } from '@angular/router'

const TOKEN_HEADER_KEY = 'Authorization'

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  constructor (private authService: AuthenticationService, private router: Router) { }

  intercept (req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    let authReq = req
    if (this.authService.getToken() != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.authService.getToken()) })
    }
    return next.handle(authReq).pipe(tap(
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log(err)
          console.log('req url :: ' + req.url)
          if (err.status === 401) {
            this.router.navigate(['user'])
          }
        }
      }
    ))
  }
}
