import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Storage } from '@capacitor/storage'
import { BehaviorSubject, from, Observable } from 'rxjs'
import { Auth } from 'aws-amplify'

const TOKEN_KEY = 'my-token'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)
  token = ''

  constructor (private http: HttpClient) {
    this.loadToken()
    this.removeToken()
  }

  async loadToken () {
    let token = null
    try {
      const session = await Auth.currentSession()
      token = session.getAccessToken()
    } catch (error) {
      console.log('error error loading token in', error)
    }

    // const token = await Storage.get({ key: TOKEN_KEY });
    if (token) {
      console.log('set token: ', token)
      this.token = token.jwtToken
      this.isAuthenticated.next(true)
    } else {
      this.isAuthenticated.next(false)
    }
  }

  getToken () {
    return this.token
  }

  async removeToken () {
    const token = null
  }
}
