import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha'
import { RouteReuseStrategy } from '@angular/router'
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular'
import Amplify from 'aws-amplify'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { InterceptorService } from './services/interceptor.service'
import { IonicStorageModule } from '@ionic/storage-angular'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { AngularEditorModule } from '@kolkov/angular-editor'

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [FormsModule, BrowserModule, IonicModule.forRoot(), AppRoutingModule, AngularEditorModule, HttpClientModule, IonicStorageModule, RecaptchaV3Module, AmplifyUIAngularModule, ReactiveFormsModule, Ng2SearchPipeModule],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lc4y_YcAAAAAIV49VtaCEgAr-NqFyD6J-O1CEKD' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
